import { Component, Injector, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-seller-type-dll',
  templateUrl: './seller-type-dll.component.html',
  styleUrls: ['./seller-type-dll.component.scss']
})
export class SellerTypeDllComponent implements OnChanges {

  @Input("SellerTypeID") sellerTypeID : number;

  SellerTypeList = [
    { id: 1, name: "Individual" },
    { id: 2, name: "Dealer" },
    { id: 3, name: "OEM" },
    { id: 4, name: "Finance Company" }
  ];
  
  constructor(private http: HttpClient, private injector:Injector) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.sellerTypeID = this.sellerTypeID;
  }

  @Output() onSelectValue = new EventEmitter();

  onChangeDLL() {
    var xC = (this.sellerTypeID==undefined) ? 0 : this.sellerTypeID;
    this.onSelectValue.emit( { SellerTypeID: xC } );
  }

}
