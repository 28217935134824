import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { SellerTypeDllComponent } from './seller-type-dll.component';

@NgModule({
  declarations: [SellerTypeDllComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ]
  ,exports: [SellerTypeDllComponent]
  ,schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SellerTypeDllModule { }
