import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { ControlDllComponent } from './control-dll.component';

@NgModule({
  declarations: [ControlDllComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ]
  ,exports: [ControlDllComponent]
  ,schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ControlDllModule { }
