import { Component, Injector, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-control-dll',
  templateUrl: './control-dll.component.html',
  styleUrls: ['./control-dll.component.scss']
})
export class ControlDllComponent implements OnChanges {

  @Input("ControllerID") controllerID : number;

  ControllerList = [
    { id: 0, name: "--NA--" },
    { id: 1, name: "Fanuc" },
    { id: 2, name: "Siemens" },
    { id: 3, name: "Amada" },
    { id: 4, name: "Bosch" },
    { id: 5, name: "Cybelec" },
    { id: 6, name: "Cypcut" },
    { id: 7, name: "Delem" },
    { id: 8, name: "Esa" },
    { id: 9, name: "Fagor" },
    { id: 10, name: "Primach" },
    { id: 11, name: "Mazatrol" },
    { id: 12, name: "OEM" },
    { id: 13, name: "Mitsubishi" },
    { id: 14, name: "Heidenhain" },
    { id: 15, name: "Acramatic 8505X" },
    { id: 16, name: "DMG-MAPPS-IV" },
    { id: 17, name: "HASS" },
    { id: 18, name: "FIDIA-C20" },
    { id: 19, name: "MSC-516" }
  ];
  
  constructor(private http: HttpClient, private injector:Injector) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.controllerID = this.controllerID;
  }

  @Output() onSelectValue = new EventEmitter();

  onChangeDLL() {
    var xC = (this.controllerID==undefined) ? 0 : this.controllerID;
    this.onSelectValue.emit( { ControllerID: xC } );
  }

}
